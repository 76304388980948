import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [btcAddress, setBtcAddress] = useState('');
  const [balances, setBalances] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasResult, setHasResult] = useState(false);

  const handleAddressChange = (e) => {
    setBtcAddress(e.target.value);
  };

  const handleQuery = async () => {
    setError('');
    setBalances([]);
    setHasResult(false);
    setLoading(true);

    const apiEndpoint = `https://api.cat20.app/api/addresses/${btcAddress}/balances`;

    try {
      const response = await axios.get(apiEndpoint);
      if (response.data.code === 0 && response.data.data.balances) {
        const balanceData = response.data.data.balances;
        setBalances(balanceData);
        setHasResult(true);
      } else {
        setError('Failed to retrieve balances.');
      }
    } catch (err) {
      setError('Failed to fetch balances. Please check the address or try again later.');
    }
    setLoading(false);
  };

  return (
      <div className="App">
        <div className="query-container">
          <h1>Search Your CAT20</h1>
          <div className="query-box">
            <input
                type="text"
                placeholder="Enter BTC Address"
                value={btcAddress}
                onChange={handleAddressChange}
            />
            <button onClick={handleQuery} disabled={loading || btcAddress.trim() === ''}>
              {loading ? 'Search...' : 'Search'}
            </button>
          </div>

          {error && <p className="error">{error}</p>}

          {hasResult && !error && balances.length === 0 && (
              <p className="no-balance">No balances found for your address.</p>
          )}

          {balances.length > 0 && (
              <div className="balances">
                <h2>Token Balances</h2>
                <ul>
                  {balances.map((token, index) => (
                      <li key={index}>
                        <span className="token-name">{token.tokenName}</span>: {token.confirmed}
                      </li>
                  ))}
                </ul>
              </div>
          )}
        </div>
      </div>
  );
}

export default App;
